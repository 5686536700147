<!--
 * @Author: cyy
 * @version: 1.4.17
 * @Date: 2022-07-28 10:10:54
 * @LastEditTime: 2022-09-07 15:18:24
 * @LastEditors: cyy
 * @Description: 图文列表
 * @FilePath: \teacherdingding.com\src\views\graphic\main.vue
-->
<template>
  <div class="graphic">
    <div
      style="padding: 20px; background-color: #fff;"
      v-if="$route.name === 'graphic'"
    >
      <div class="search">
        <el-button
          type="primary"
          size="medium"
          @click="
            $router.push({
              path: '/graphic/newGraphic',
              query: { id: 'new' },
            })
          "
        >
          新建图文
        </el-button>
        <div class="flex-center">
          <el-form
            ref="ruleIngredientsForm"
            label-width="60px"
            class="flex-center"
            :model="search"
            size="small"
          >
            <el-form-item label="状态" prop="status">
              <div class="search-item8">
                <el-select v-model="search.status" size="medium">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
          <div class="search-item8">
            <el-input
              clearable
              v-model="search.search"
              @keyup.enter.native="filterStart"
              style="width:218px"
              class="ml20"
              size="medium"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
          <div class="search-item8">
            <el-button
              type="primary"
              size="medium"
              class="ml20"
              @click="filterStart"
            >
              搜索
            </el-button>
          </div>
        </div>
      </div>

      <pagination2
        :option="post"
        url="/Graphic/graphicList"
        ref="childDialogBox"
        class="pagination2"
        @complete="pagination2Complete"
      >
        <template>
          <el-table
            @cell-mouse-enter="cellMouseEnter"
            @cell-mouse-leave="cellMouseLeave"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="dataList"
          >
            <el-table-column label="图文" prop="name">
              <template slot-scope="{ row }">
                <div class="name_contain">
                  <div class="tu">
                    <!-- <span v-if="row.show">lsfjdslfsdlfj</span> -->
                    <img :src="row.cover_img" alt="" />
                  </div>
                  <div class="right">
                    <div class="name hidetext" :title="row.name">
                      {{ row.name }}
                    </div>
                    <div class="price_cross">
                      <div class="price">
                        <span v-if="row.price_type == 2">免费</span>
                        <span v-else-if="row.price_type == 3">
                          密码：{{ row.fee_pwd }}
                        </span>
                        <span v-else>￥{{ row.price }}</span>
                      </div>
                      <div
                        class="cross"
                        v-if="row.price_type == 1 && row.cost_price > 0"
                      >
                        {{ row.cost_price }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="有效期" width="160px">
              <template slot-scope="{ row }">
                <span v-if="row.validity_type == 1">永久有效</span>
                <span v-else-if="row.validity_type == 2">
                  {{ row.validity }}天
                </span>
                <span v-else>
                  {{ row.validity | formatTimeStamp }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="已售"
              prop="order_num"
              width="100px"
            ></el-table-column>
            <el-table-column label="创建时间" width="180px">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp }}
              </template>
            </el-table-column>
            <el-table-column label="状态" width="80px">
              <template slot-scope="{ row }">
                <span style="color:#3D80EF" v-if="row.status == 1">已上架</span>
                <span v-else class="redcolor">已下架</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="250px"
              align="right"
              fixed="right"
            >
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: '/graphic/detailGraphic',
                        query: { id: row.graphic_id },
                      })
                    "
                  >
                    详情
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click="
                      $router.push({
                        path: '/graphic/newGraphic',
                        query: { id: row.graphic_id },
                      })
                    "
                  >
                    编辑
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="tuiguang(row)">
                    分享
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button
                    type="text"
                    @click="setStatus(row, 2)"
                    v-if="row.status == 1"
                  >
                    下架
                  </el-button>
                  <el-button type="text" @click="setStatus(row, 1)" v-else>
                    上架
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="del(row)">
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>

      <!-- 推广 -->
      <extension
        v-if="status"
        :selectrow="selectrow"
        :dialogstatus.sync="status"
      ></extension>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import bg from '@/mixin/background'
import extension from './components/extension'
export default {
  name: 'graphic',

  mixins: [bg],

  components: {
    extension,
  },

  data() {
    return {
      dataList: [],

      options: [
        { label: '全部', value: 0 },
        { label: '已上架', value: 1 },
        { label: '已下架', value: 2 },
      ],

      post: {},

      selectrow: {},

      status: false,

      search: {
        search: '',
        status: 0,
      },
    }
  },

  methods: {
    // 上下架
    async setStatus(row, val) {
      if (val == 2) {
        this.$confirm('是否确定下架该图文？', '下架', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(async () => {
            await this.setfn(row, val)
          })
          .catch(() => {})
      } else {
        await this.setfn(row, val)
      }
    },

    async setfn(row, val) {
      await this.$http({
        url: '/Graphic/setStatus',
        data: {
          graphic_id: row.graphic_id,
          status: val,
        },
        successMsg: true,
      })
      this.reset()
    },

    // 删除
    async del(row) {
      this.$confirm('是否确定删除该图文？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Graphic/delGraphic',
            data: {
              graphic_id: row.graphic_id,
            },
            successMsg: true,
          })
          this.reset()
        })
        .catch(() => {})
    },

    // 获取列表返回数据
    pagination2Complete(val) {
      this.dataList = val.data.map(item => {
        return {
          ...item,
          show: false,
        }
      })
    },

    // 当单元格 hover 进入时会触发该事件
    cellMouseEnter(row) {
      this.index = this.dataList.findIndex(
        item => item.graphic_id == row.graphic_id
      )
      if (this.index > -1) {
        this.dataList[this.index].show = true
      }
    },

    // 当单元格 hover 出去时会触发该事件
    cellMouseLeave() {
      this.dataList[this.index].show = false
    },

    //分享
    tuiguang(row) {
      this.selectrow = row
      this.$nextTick(() => {
        this.status = true
      })
    },

    reset() {
      this.$refs.childDialogBox.reset()
    },

    // 筛选
    filterStart() {
      if (this.$refs['ruleIngredientsForm']) {
        this.$refs['ruleIngredientsForm'].validate(valid => {
          if (valid) {
            this.post = _.assign({}, this.search)
            this.reset()
          } else {
            return false
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.graphic {
  margin: 20px;
  min-width: 900px;
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    ::v-deep .el-input-group--append .el-input__inner {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .middle-space {
      font-size: 14px;
      line-height: 19px;
      margin: 0 10px;
      color: rgba(51, 51, 51, 1);
    }
    .search-text {
      min-width: 56px;
      font-size: 14px;
      line-height: 19px;
      margin-right: 10px;
      color: rgba(51, 51, 51, 1);
    }

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
    .search-item {
      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }
    }
    ::v-deep .search-item5,
    ::v-deep .search-item7,
    ::v-deep .search-item8 {
      .el-input {
        width: 130px;
        position: relative;
        input[type='number'] {
          padding-right: 50px;
        }
        .el-input-group__append {
          position: absolute;
          right: 0;
          background: none;
          top: 0;
          height: 100%;
          border: 0;
          line-height: 36px;
          width: auto;
          padding: 0;
          padding-right: 10px;
        }
      }
    }
  }
  .pagination2 {
    .name_contain {
      display: flex;
      align-items: center;
      line-height: 1.5;
      .tu {
        flex-shrink: 0;
        width: 100px;
        height: 56px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        flex: 1;
        overflow: hidden;
        .name {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 14px;
        }
        .price_cross {
          display: flex;
          align-items: center;
          .price {
            font-size: 14px;
            color: #ff3535;
            margin-right: 10px;
          }
          .cross {
            font-size: 14px;
            color: #a1a1a1;
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .btns {
    text-align: right;
  }

  ::v-deep .el-divider {
    background-color: #1b9d97 !important;
  }
}
</style>
